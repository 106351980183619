<template>
    <div v-if="device">
        <div class="container repair-container">

            <loading-state
                    v-if="loading"
                    title="Loading iris codes">
            </loading-state>

            <!-- Search and Breadcrumb -->
            <template v-if="currentIrisCode !== 'complete'">
                <!-- Search -->
                <div class="row mb-2">
                    <div class="col-md-12">
                        <label class="mt-2">Select the code {{ currentIrisCode }} iris</label>
                    </div>
                </div>

                <!-- Breadcrumb -->
                <div class="card bg-transparent mb-3 text-muted text-sm" v-if="irisCodeOne">
                    <div>
                        <span v-if="irisCodeOne" @click="editIrisCodeOne()">Code One: <strong class="text-underline cursor-pointer">{{ irisCodeOne.description }}</strong></span>
                        <span v-if="irisCodeTwo" @click="editIrisCodeTwo()"> &nbsp;/&nbsp; Code Two: <strong class="text-underline cursor-pointer">{{ irisCodeTwo.description }}</strong></span>
                        <span v-if="irisCodeThree" @click="editIrisCodeThree()"> &nbsp;/&nbsp; Code Three: <strong class="text-underline cursor-pointer">{{ irisCodeThree.description }}</strong></span>
                    </div>
                </div>
            </template>

            <!-- Code one -->
            <template v-if="!irisCodeOne && !irisCodeTwo && !irisCodeThree">
                <div class="card-grid vertical">
                    <div class="card-link-container" v-for="irisOneCode in irisOneCodes" :key="irisOneCode.code"><div class="card card-body card-body-sm card-link" @click="setIrisCodeOne(irisOneCode)">{{ irisOneCode.description }}</div></div>
                </div>
            </template>

            <!-- Code two -->
            <template v-if="irisCodeOne && !irisCodeTwo && !irisCodeThree">
                <div class="card-grid vertical">
                    <div class="card-link-container" v-for="irisTwoCode in irisTwoCodes" :key="irisTwoCode.code"><div class="card card-body card-body-sm card-link" @click="setIrisCodeTwo(irisTwoCode)">{{ irisTwoCode.description }}</div></div>
                </div>
            </template>

            <!-- Code three -->
            <template v-if="irisCodeOne && irisCodeTwo && !irisCodeThree">
                <div class="card-grid vertical">
                    <div class="card-link-container" v-for="irisThreeCode in irisThreeCodes" :key="irisThreeCode.code"><div class="card card-body card-body-sm card-link" @click="setIrisCodeThree(irisThreeCode)">{{ irisThreeCode.description }}</div></div>
                </div>
            </template>

            <!-- Code four -->
            <template v-if="irisCodeOne && irisCodeTwo && irisCodeThree && !irisCodeFour">
                <div class="card-grid vertical">
                    <div class="card-link-container" v-for="irisFourCode in irisFourCodes" :key="irisFourCode.code"><div class="card card-body card-body-sm card-link" @click="completeIrisCodes(irisFourCode)">{{ irisFourCode.description }}</div></div>
                </div>
            </template>

            <!-- Confirmation -->
            <template v-if="currentIrisCode === 'complete'">
                <h2>Selected iris codes</h2>
                <ul class="list-group">
                    <li class="list-group-item bg-secondary">
                        <div class="d-flex">
                            <div style="width: 120px;">
                                Code One:
                            </div>
                            <strong>{{ irisCodeOne.description }}</strong>
                        </div>
                        <button class="btn btn-info btn-sm" @click="editIrisCodeOne()">Edit</button>
                    </li>
                    <li class="list-group-item bg-secondary">
                        <div class="d-flex">
                            <div style="width: 120px;">
                                Code Two:
                            </div>
                            <strong>{{ irisCodeTwo.description }}</strong>
                        </div>
                        <button class="btn btn-info btn-sm" @click="editIrisCodeTwo()">Edit</button>
                    </li>
                    <li class="list-group-item bg-secondary">
                        <div class="d-flex">
                            <div style="width: 120px;">
                                Code Three:
                            </div>
                            <strong>{{ irisCodeThree.description }}</strong>
                        </div>
                        <button class="btn btn-info btn-sm" @click="editIrisCodeThree()">Edit</button>
                    </li>
                    <li class="list-group-item bg-secondary">
                        <div class="d-flex">
                            <div style="width: 120px;">
                                Code Four:
                            </div>
                            <strong>{{ irisCodeFour.description }}</strong>
                        </div>
                        <button class="btn btn-info btn-sm" @click="editIrisCodeFour()">Edit</button>
                    </li>
                </ul>
            </template>

            <!-- Footer -->
            <fixed-footer
                    v-if="currentIrisCode === 'complete' || this.isJobManager"
                    :class="{'active': footerActive}"
                    :nextLabel="this.nextRepairStep($route.name).title"
                    :nextRoute="this.nextRepairStep($route.name).routeName"
                    :prevRoute="this.prevRepairStep($route.name).routeName">
            </fixed-footer>

        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';

    export default {

        props:['jobId', 'deviceId'],

        data() {
            return {
                loading: true
            }
        },

        mounted() {
            this.loadDeviceForJob({
                jobId: this.jobId,
                deviceId: this.deviceId,
            }).then(() => {
                this.setCurrentStep({
                    jobId: this.jobId,
                    deviceId: this.deviceId,
                    currentStep: this.$route.name,
                });
            });

            this.loadIrisCodes()
                .then(() => {
                    this.loadIrisCodesForDevice({
                        jobId:this.jobId,
                        deviceId:this.deviceId
                    }).then(() => {
                        let self = this;
                        setTimeout(() =>{
                            self.loading = false
                        }, 800);
                    });
                });
        },

        computed: {
            ...mapGetters([
                "currentIrisCode",
                "device",
                "irisOneCodes",
                "irisTwoCodes",
                "irisThreeCodes",
                "irisFourCodes",
                "irisCodeOne",
                "irisCodeTwo",
                "irisCodeThree",
                "irisCodeFour",
                "isJobManager",
                "nextRepairStep",
                "prevRepairStep"
            ]),

            /** Check if footer should be active */
            footerActive() {
                if (this.currentIrisCode === 'complete' || this.isJobManager) {
                    return true;
                }

                return false;
            }
        },

        methods: {
            ...mapActions([
                "confirmIrisCodes",
                "editIrisCodeOne",
                "editIrisCodeTwo",
                "editIrisCodeThree",
                "editIrisCodeFour",
                "loadDeviceForJob",
                "loadIrisCodes",
                "loadIrisCodesForDevice",
                "setCurrentStep",
                "setIrisCodeOne",
                "setIrisCodeTwo",
                "setIrisCodeThree",
                "setIrisCodeFour"
            ]),

            completeIrisCodes(irisFourCode) {
                this.setIrisCodeFour(irisFourCode)
                    .then(() => {
                        this.confirmIrisCodes({
                            jobId:this.jobId,
                            deviceId:this.deviceId
                        });
                    });
            }
        }
    }
</script>